.card-hotel {
    padding: 15px;
    max-width: 377px;
    width: 100%;
    
    @include media-breakpoint-up(lg) {
        max-width: none;
        flex-direction: row;
    }

    .photos {
        overflow: hidden;
        max-height: 243px;
        border-radius: 5px;
        
        @include media-breakpoint-up(lg) {
            max-width: 385px;
            min-width: 385px;
            max-height: 276px;
        }
    }
    
    .photo {
        border-radius: 5px;
    }
    
    .info {
        padding: 28px 3px 4px 3px;
        
        @include media-breakpoint-up(lg) {
            padding: 2px 0 0 24px;
        }
    }

    .title {
        padding-bottom: 7px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        color: rgba($default-color, .8);
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 3px;
            font-size: 22px;
        }
    }

    .address {
        padding-bottom: 15px;
        font-weight: 300;
        font-size: 12px;
        line-height: 1.3333;
        letter-spacing: .08em;
        color: rgba($default-color, .4);
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 20px;
            letter-spacing: .1em;
            font-size: 10px;
            line-height: 1.4;
        }
    }

    .description {
        padding-bottom: 18px;
        font-size: 14px;
        line-height: 1.3571;
        letter-spacing: .03em;
        color: rgba($default-color, .75);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .term {
        padding-bottom: 17px;
        letter-spacing: .02em;
        color: rgba($default-color, .5);
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 16px;
            font-size: 14px;
        }
    }

    .features {
        @extend .clearlist;

        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 39px;
            margin: 0 -10px;
        }
    }

    .feature {
        position: relative;
        font-size: 14px;
        letter-spacing: .03em;
        color: rgba($default-color, .4);
        margin: 0 7px;
        padding-bottom: 3px;
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            font-size: 10px;
            margin: 0 10px;
        }

        .icon {
            margin-right: 9px;
            line-height: 14px;
            vertical-align: middle;

            @include media-breakpoint-up(lg) {
                
            }

            &.icon-breakfast {
                font-size: 12px;
            }
            
            &.icon-conditioner {
                font-size: 11px;
            }
            
            &.icon-wi-fi {
                font-size: 9px;
            }
        }

    }

    .note {
        padding-bottom: 19px;
        font-weight: 300;
        font-size: 8px;
        line-height: 1.375;
        letter-spacing: .1em;
        color: rgba($default-color, .4);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .location {
        position: relative;
        padding-left: 16px;
        font-size: 12px;
        color: rgba($default-color, .25);

        @include media-breakpoint-down(md) {
            display: none;
        }

        .icon {
            position: absolute;
            left: 0px;
            font-size: 10px;
            line-height: 12px;
            color: rgba($hotel-location-icon-color, .25);
        }
    }
}