.navbar {
    @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar-brand {
        position: absolute;
        top: 19px;
        left: 16px;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            top: 36px;
            left: 54px;
        }

        img {
            width: 78px;

            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }
    }

    .navbar-toggler {
        border-width: 0;
        font-size: 0;
        font-size: 15px;
        color: $navbar-toggler-icon-color;
        width: 27px;
        height: 37px;
        line-height: 19px;
    }

    .navbar-nav {
        
        .nav-link {
            font-family: $font-secondary;
            font-size: 18px;
            letter-spacing: .04em;
            text-transform: uppercase;
        }
    }
}