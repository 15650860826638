.section-package-tickets {
    padding: 25px 0 0;
    
    @include media-breakpoint-up(lg) {
        padding: 21px 0 0;
    }

    .text {
        color: rgba($default-color, .75);
        text-align: center;
        font-size: 12px;
        padding: 9px 0;
        letter-spacing: .05em;
        
        @include media-breakpoint-up(lg) {
            padding: 5px 0;
            color: rgba($default-color, .5);
            font-size: $default-font-size;
            text-align: left;
            letter-spacing: .03em;
        }
    }

    .matches {
        overflow: hidden;
        margin-top: -7px;
        padding: 0px 0 4px;
        
        @include media-breakpoint-up(lg) {
            margin-top: 0;
            padding: 38px 0 34px;
        }
    }
}