.component-socials {
    @extend .clearlist;

    display: flex;
    justify-content: center;

    .social {
        $width-base: 20px;

        background: no-repeat center;
        height: $width-base + 21px;
        margin: 0 9px;
        position: relative;
        font-size: 21px;
        
        @include media-breakpoint-up(lg){
            height: $width-base + 19px;
            margin: 0 20px;
            font-size: 18px;
        }

        a {
            color: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 10px;
            position: relative;
            z-index: 1;

            &:hover {
                text-decoration: none;
            }
        }
    }
}