.section-copyright {
    padding: 10px 0 69px;
    text-align: center;
    
    @include media-breakpoint-up(lg) {
        padding: 20px 0 62px;
    }

    .sponsor {
        max-width: 119px;
        margin-bottom: 32px;
        
        @include media-breakpoint-up(lg) {
            margin-bottom: 54px;
            max-width: none;
        }
    }

    .copyright {
        color: rgba($default-color, .25);
        font-weight: 700;
        line-height: 1.1875;
        letter-spacing: 0;

        @include media-breakpoint-up(lg) {
            line-height: 1.6875;
        }
    }
}