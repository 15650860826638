.component-sticky {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateY(100%);
    transition-duration: .7s;
    opacity: 0;
    
    &.visible {
        transform: translateY(0);
        opacity: 1;
    }

    &.fixed {
        position: fixed;
    }
}