.component-info {
    @extend .clearlist;

    .info-item {
        background: no-repeat left center;
        padding-left: 38px;
        margin: 12px 0;
        font-size: 18px;
        line-height: 1.2222;
        letter-spacing: .03em;
        color: rgba($default-color, .65);
        position: relative;
        
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 1.5714;
            letter-spacing: .02em;
        }

        .icon {
            color: rgba($info-icon-color, .5);
            position: absolute;
            left: 1px;
            font-size: 21px;
            line-height: 21px;
        
            &.icon-badge {
                font-size: 22px;
            }
        }
    }
}