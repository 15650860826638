.owl-carousel {

    .owl-stage-outer {
        border-radius: 5px;
    }

    .owl-nav {

        button.owl-prev,
        button.owl-next {
            position: absolute;
            top: 50%;
            margin-top: -13px;
            width: 27px;
            height: 26px;
            line-height: 26px;
            font-size: 31px;
            border-radius: 40px;
            background-color: rgba($carousel-nav-bg-color, .6);
            color: rgba($default-color, .8);
            
            @include media-breakpoint-up(lg) {
                margin-top: -17px;
                width: 35px;
                height: 34px;
                line-height: 34px;
                font-size: 44px;
                background-color: rgba($carousel-nav-bg-color, .7);
            }

            span {
                display: block;
                margin-top: -4px;
                
                @include media-breakpoint-up(lg) {
                    margin-top: -7px;
                }
            }
        }

        button.owl-prev {
            left: 15px;
            
            @include media-breakpoint-up(lg) {
                left: 18px;
            }
        }

        button.owl-next {
            right: 15px;

            @include media-breakpoint-up(lg) {
                right: 18px;
            }
        }
    }
}