.section-package-hotels {

    .hotels {
        display: flex;
        justify-content: center;
        padding: 19px 0 23px;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            padding: 60px 0 50px;
        }
    }
}