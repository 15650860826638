.section-package-includes {
    padding: 0 0 10px;

    .package-title {
        text-align: left;
        letter-spacing: .03em;
    }
    
    .component-features {
        padding-bottom: 19px;
        color: rgba($default-color, .75);
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 29px;
        }
    }
    
    .itinerary {
        display: flex;
        align-items: flex-end;
        font-size: 14px;
    }
    
    .text {
        padding-right: 12px;
        color: rgba($default-color, .6);
        letter-spacing: .03em;
    }

    .link {
        display: inline-block;
        position: relative;
        font-weight: 300;
        font-style: italic;
        color: rgba($section-package-includes-link-color, .5);
        white-space: nowrap;

        .icon {
            width: 0;
            margin-left: 6px;
            font-size: 19px;
            color: $section-package-includes-link-color;
            
            @include media-breakpoint-up(lg) {
                color: rgba($section-package-includes-link-color, .5);
            }
        }

        &:hover {
            text-decoration: none;

            .link-text {
                text-decoration: underline;
            }

            .icon {
                text-decoration: none;
            }
        }
    }
}