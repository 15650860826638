.section-info {
    padding-top: 9px;
    padding-bottom: 10px;

    @include media-breakpoint-up(lg) {
        padding-top: 41px;
        padding-bottom: 30px;
    }
    
    .component-info {
        padding-bottom: 20px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 25px;
        }
    }

    .btn {
        margin-right: 10px;
    }
}