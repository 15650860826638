.form-control {
    border-color: $border-color;
    padding: 0 11px;
    line-height: 48px;
    height: 48px;
    letter-spacing: .02em;
    font-family: $font-secondary;
    color: rgba($default-color, .35);
    font-size: $default-font-size;
    
    @include media-breakpoint-up(lg) {
        font-size: 18px;
        padding: 0 22px;
    }

    &.border-radius-0 {

        &:focus {
            border-bottom-left-radius: 0;

            & + .input-group-append {
                border-bottom-right-radius: 0;

                .btn {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
    
    &:focus {
        border-color: $border-color;
        color: rgba($default-color, .35);
    }
    
    &::placeholder {
        color: rgba($default-color, .35);
    }
    
    &.active {
        color: rgba($default-color, .7);
    }
}