// ############ Colors ############
$white:             #fff;
$gray-100:          #fcfcfc;
$gray-200:          #fafafa;
$gray-250:          #f9f9f9;
$gray-270:          #f7f7f7;
$gray-290:          #f4f4f4;
$gray-300:          #f3f3f3;
$gray-350:          #f1f1f1;
$gray-345:          #f0f0f0;
$gray-375:          #eee;
$gray-400:          #e8e8e8;
$gray-450:          #d6d6d6;
$gray-500:          #97989a;
$gray-600:          #6c6c6c;
$gray-700:          #565656;
$gray-900:          #1d1d1d;
$black:             #000;
$blue:              #53c8e9;
$blue-dark:         #079;
$blue-2:            #3bd8c2;
$blue-2-dark:       #11827a;
$blue-3:            #56ccf2;
$blue-4:            #009886;
$blue-5:            #005c92;
$green:             #009987;
$red:               #f6436d;
$red-dark:          #8e0322;
$red-2:             #f13b54;
$red-3:             #fc0000;
$red-4:             #f00;
$purple:            #993dba;
$purple-dark:       #450d63;
$purple-2:          #452761;
$purple-2-light:    #7c4993;
$pink:              #c33a8c;
$yellow:            #fbb531;

$color-pool-a:          $blue;
$color-pool-b:          $red;
$color-pool-c:          $blue-2;
$color-pool-d:          $purple;
$color-pool-not-known:  $gray-500;

$pools-colors: () !default;
$pools-colors: map-merge(
    (
        "pool-a":         $color-pool-a,
        "pool-b":         $color-pool-b,
        "pool-c":         $color-pool-c,
        "pool-d":         $color-pool-d,
        "pool-not-known": $color-pool-not-known
    ),
    $pools-colors
);