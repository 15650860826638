.component-get-package {
    display: flex;
    align-items: center;
    margin: 0 -18px;
    padding: 14px;
    background-color: $get-package-bg-color;
    transition-duration: .3s;
    border: 0 solid $get-package-border-color-mobile;
    border-top-width: 1px;
    
    @include media-breakpoint-up(sm) {
        border-left-width: 1px;
        border-right-width: 1px;
        margin: 0 -38px;
        border-radius: 3px 3px 0 0;
    }

    @include media-breakpoint-up(md) {
        margin: 0 -44px;
    }
    
    @include media-breakpoint-up(lg) {
        margin: 0 -34px;
        border-color: $get-package-border-color-desktop;
        padding: 6px 50px 6px 6px;
    }
    @include media-breakpoint-up(xl) {
        margin: 0 -90px;
    }

    .img-wrapper {

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .img {
        border-radius: 3px;
    }

    .text-wrapper {
        flex-grow: 1;
        padding-bottom: 2px;
        
        @include media-breakpoint-up(lg) {
            padding-left: 20px;
            padding-bottom: 0;
        }
    }

    .title {
        margin-bottom: 6px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        color: rgba($default-color, .7);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .period {
        margin-bottom: 6px;
        font-size: 14px;
        color: rgba($default-color, .5);
        letter-spacing: .03em;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .price-text {
        margin-bottom: 2px;
        font-weight: 300;
        font-size: 8px;
        color: rgba($default-color, .5);
        letter-spacing: .03em;
        
        @include media-breakpoint-up(lg) {
            margin-bottom: 3px;
            font-size: 12px;
            color: rgba($default-color, .4);
        }
    }

    .price {
        margin-left: -1px;
        font-weight: 700;
        font-size: 22px;
        color: rgba($default-color, .7);
        letter-spacing: .05em;
        
        @include media-breakpoint-up(lg) {
            margin-left: -2px;
            font-size: 34px;
            color: rgba($default-color, .75);
        }
    }
}

.component-sticky.fixed .component-get-package {

    @include media-breakpoint-up(lg) {
        box-shadow: 0px -5px 30px rgba($get-package-box-shadow-color, .1);
    }
}