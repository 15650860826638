.component-tabs {
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    
    .tab {
        position: relative;
        padding: 10px 0 1px;
        left: 0;
        right: 0;
        transition: opacity .3s ease;
        opacity: 0;
    }
    
    &.active .tab {
        top: 0;
        position: absolute;
        z-index: 1;

        &.active{
            position: relative;
            z-index: 2;
            opacity: 1;
        }
    }
}