.section-packages {

    padding-bottom: 20px;
    
    @include media-breakpoint-up(lg) {
        padding-bottom: 30px;
    }
    
    .packages {
        padding: 8px 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -11px;
        
        @include media-breakpoint-up(lg) {
            padding: 24px 0 20px;
            justify-content: space-between;
        }
    }

    .contact {
        padding: 19px 0;
        
        p {
            color: rgba($default-color, .25);
            line-height: 1;
            margin: 7px;
            text-align: center;
            letter-spacing: 0;
            
            @include media-breakpoint-up(lg) {
                font-size: 20px;
                line-height: 1.35;
            }
        }

        a {
            text-decoration: underline;
        }
    }
}