.section-package-main {
    background: $section-package-main-bg-color center/cover;
    color: $section-package-main-color;
    text-align: center;
    margin-top: 63px;
    position: relative;
    
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
    }
    
    @include media-breakpoint-down(md) {
        background-image: none !important;
    }
    
    .bg-mobile {
        background: center/cover;
        
        @include media-breakpoint-up(lg) {
            background-image: none !important;
        }
    }
    
    .overlay {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        min-height: 526px;
        background: rgba($section-package-main-overlay-bg-color, .3);
        
        @include media-breakpoint-up(lg) {
            min-height: 537px;
            background: rgba($section-package-main-overlay-bg-color, .2);
        }
    }
    
    .title {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-family: $font-tertiary;
        
        @include media-breakpoint-down(md) {
            min-height: 179px;
            padding-top: 31px;
            padding-bottom: 25px;
            letter-spacing: .09em;
            align-content: flex-end;
            align-items: flex-end;
            
            .m-lc {
                text-transform: lowercase;
            }
            
            .m-fs-20 {
                display: block;
                font-size: 20px;
                text-align: right;
                padding: 0 0 11px;
                margin-top: -6px;
                margin-right: -35px;
            }
            
            .m-fs-38 {
                display: block;
                font-size: 38px;
                padding: 5px 0 6px;
            }
            
            .m-fs-40 {
                display: block;
                font-size: 40px;
                padding: 5px 0 6px;
            }
            
            .m-fs-48 {
                display: block;
                font-size: 48px;
                padding: 5px 0 6px;
            }
            
            .m-fs-52 {
                display: block;
                font-size: 52px;
                padding: 5px 0 6px;
            }
            
            .m-fs-62 {
                display: block;
                font-size: 62px;
                padding: 5px 0 6px;
            }

            .m-pb-19 {
                padding-bottom: 19px;
            }
        }
        
        @include media-breakpoint-up(lg) {
            min-height: 384px;
            padding-top: 72px;
            align-content: center;
            align-items: center;
            letter-spacing: .1em;
            
            .m-only {
                display: none;
            }
            
            .d-fs-80 {
                display: block;
                font-size: 80px;
                padding: 13px 0;
            }
            
            .d-fs-100 {
                display: block;
                font-size: 100px;
                padding: 13px 0;
            }
            
            .d-fs-110 {
                display: block;
                font-size: 110px;
                padding: 13px 0;
            }
            
            .d-fs-120 {
                display: block;
                font-size: 120px;
                padding: 13px 0;
            }
            
            .d-fs-140 {
                display: block;
                font-size: 140px;
                padding: 13px 0;
            }
        }
    }

    .date {
        letter-spacing: .06em;
        
        @include media-breakpoint-up(lg) {
            letter-spacing: .03em;
            font-size: 38px;
        }
    }

    .agent-logo {
        padding-top: 50px;
        
        @include media-breakpoint-up(lg) {
            padding: 0 0 11px 15px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 0 38px 55px;
        }

        img {
            width: 160px;

            @include media-breakpoint-up(lg) {
                width: 100px;
            }

            @include media-breakpoint-up(xl) {
                width: 140px;
            }
        }
    }

    .copyright {
        padding: 37px 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            padding: 17px 0 62px;
        }

        img {
            max-width: 100%;
            display: block;
            flex: 0 0 auto;
            margin-right: 10px;
            width: 20px;
            
            @include media-breakpoint-up(lg) {
                margin-right: 9px;
                width: 27px;
            }
        }

        span {
            font-weight: 700;
            font-size: 8px;
            display: block;
            
            @include media-breakpoint-up(lg) {
                font-size: 10px;
            }
        }
    }
}