html {
    height: 100%;
}

body {
    height: 100%;
    position: relative;
    font: #{$default-font-weight} #{$default-font-style} #{$default-font-size} / #{$default-line-height} #{$default-font-family};
    color: $default-color;
    background-color: $body-bg;
    min-width: 320px;
}

.layout {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

.container-lg {
    max-width: 1154px;
}

.container-sm {
    max-width: 788px;
}

h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba($default-color, .9);
    margin: 28px 0 11px;
    text-align: center;
    
    @include media-breakpoint-up(lg) {
        margin: 31px 0 17px;
        text-align: left;
        font-size: 30px;
    }
}

.package-title {
    color: rgba($default-color, .8);
    letter-spacing: .05em;
    margin-bottom: 2px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 7px;
    }
}

p {
    line-height: 1.6875;
    letter-spacing: .03em;
    color: rgba($default-color, .65);
    margin: 6px 0;
}

.clearlist {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

a {
    cursor: pointer;
}

img {
    max-width: 100%;
}

.page-content-wrap {
    color: $page-content-wrap-color;
    max-width: 100%;

    .title {
        margin-bottom: 36px;
    }

    p {
        margin-bottom: 30px;
    }
}

.image-caption {
    margin: 10px 0;
    color: rgba($default-color, .3);
    font-size: 10px;
    line-height: 1.2;
}