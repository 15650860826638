.card-flight {
    word-wrap: normal;
    margin: 11px 0 10px;
    max-width: 377px;
    width: 100%;
    
    @include media-breakpoint-up(lg) {
        margin: 15px;
        max-width: 466px;
    }

    .head {
        margin: (-$card-border-width) (-$card-border-width) 0;
        display: flex;
        align-items: center;
        min-height: 104px;
        background: url("#{$path-img}flight-bg-mobile.jpg") center/cover;
        border-radius: $card-border-radius $card-border-radius 0 0;
        color: $flight-head-color;
        
        @include media-breakpoint-up(lg) {
            min-height: 148px;
            background-image: url("#{$path-img}flight-bg-desktop.jpg");
        }

        &::before {
            content: " ";
            min-height: inherit;
            font-size: 0;
        }

        .from,
        .to {
            width: 50%;
            padding: 0 18px;
            
            @include media-breakpoint-up(lg) {
                padding: 0 21px;
            }
        }

        .to {
            text-align: right;
        }

        .code {
            font-weight: 700;
            font-size: 42px;
            margin: 0 -2px;
            
            @include media-breakpoint-up(lg) {
                font-size: 48px;
            }
        }

        .city {
            padding: 0 0 6px;
            
            @include media-breakpoint-up(lg) {
                padding: 3px 0 6px;
                font-size: 18px;
                letter-spacing: .02em;
            }
        }
    }

    .body {
        min-height: 1px;
        border-top-width: 0;
        border-radius: 0 0 $card-border-radius $card-border-radius;
        padding: 0 18px;
        
        @include media-breakpoint-up(lg) {
            padding: 0 8px;
        }
        
        .detail {
            display: flex;
            padding: 6px 0 10px;
            margin: 0 -3px;
            
            @include media-breakpoint-up(lg) {
                margin: 0;
                padding: 14px 13px 16px;
            }

            &:nth-of-type(n+2) {

                border-top: 2px solid $flight-divider-color-mobile;

                @include media-breakpoint-up(lg) {
                    border-color: $flight-divider-color-desktop;
                }
            }
        }

        .from,
        .to {
            width: 1px;
            flex-grow: 1;
            letter-spacing: .01em;
            
            @include media-breakpoint-up(lg) {
                letter-spacing: .02em;
            }
        }

        .to {
            text-align: right;
        }

        .date {
            color: rgba($default-color, .7);
            font-size: 12px;
            padding-top: 7px;
            
            @include media-breakpoint-up(lg) {
                font-size: 14px;
            }
        }

        .time {
            color: rgba($default-color, .8);
            font-size: 24px;
            padding: 6px 0 2px;
            
            @include media-breakpoint-up(lg) {
                color: $default-color;
                font-size: 36px;
                padding: 7px 0 4px;
            }
        }
        
        .location {
            color: rgba($default-color, .7);
            font-size: 12px;
            line-height: 1.25;
        }

        .info {
            width: 80px;
            text-align: center;
            
            @include media-breakpoint-up(lg) {
                width: 100px;
            }
        }

        .title {
            color: rgba($default-color, .5);
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: .02em;
            padding-top: 4px;
            
            @include media-breakpoint-up(lg) {
                padding-top: 1px;
            }
        }

        .transfer {
            padding-top: 18px;
            position: relative;
            
            @include media-breakpoint-up(lg) {
                padding-top: 22px;
            }
            
            .icon {
                color: rgba($default-color, .7);
                position: absolute;
                left: 9px;
                top: 29px;
                font-size: 5px;
                
                @include media-breakpoint-up(lg) {
                    left: -1px;
                    top: 33px;
                    font-size: 12px;
                }

                &.icon-transfer-desktop {
                    
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &.icon-transfer-mobile {

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }
            }
        }

        .code {
            color: rgba($default-color, .7);
            font-weight: 700;
            font-size: 8px;
            padding-bottom: 12px;
            
            @include media-breakpoint-up(lg) {
                font-size: 10px;
                padding-bottom: 13px;
            }
        }
        
        .duration {
            color: rgba($default-color, .4);
            font-size: 6px;
            
            @include media-breakpoint-up(lg) {
                font-weight: 300;
                font-size: 10px;
            }
        }
    }
}