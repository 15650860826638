.vdp-datepicker {

    .vdp-datepicker__calendar {
        width: 391px;
        border-width: 0;
        overflow: hidden;
        margin: 0;

        header {
            @extend .clearfix;
            box-shadow: 0 5px 30px rgba($default-color, .05);
            line-height: 78px;

            .prev,
            .next {
                @extend .icon, .icon-arrow-3;

                color: transparent;
                font-size: 11px;
                text-indent: 0;
                line-height: 78px;
                transition-duration: .3s;
                
                &::before {
                    transition-duration: .3s;
                    color: rgba($default-color, .2);
                }
                
                &::after {
                    display: none;
                }
                
                &.disabled {
                    opacity: 0;
                }

                &:not(.disabled):hover {
                    background-color: transparent;

                    &::before {
                        color: rgba($default-color, .3);
                    }
                }
            }

            .prev {
                padding-left:4px;
                transform: scaleX(-1);
            }

            .day__month_btn {
                font-size: 24px;
                line-height: 76px;
                font-weight: 700;
                color: rgba($default-color, .65);
                letter-spacing: .02em;
            }

            .next {
                padding-right: 4px;
            }

            // Body
            & + * {                
                padding: 35px 24px 20px;
            }
        }

        .cell {
            transition-duration: .3s;
            height: 24px;
            line-height: 24px;
            border-radius: 24px;
            width: 24px;
            font-weight: 700;
            font-size: 14px;
            padding: 0;
            border-width: 0;
            margin: 5px 12px 5px 13px;
            
            &.day-header {
                color: rgba($default-color, .75);
                margin-top: 0;
                margin-bottom: 22px;
                font-size: 14px;
            }
            
            &.day {
                color: rgba($default-color, .45);
                
                &:hover {
                    color: rgba($default-color, .75);
                }
                
                &.disabled {
                    color: rgba($default-color, .2);
                }
                
                &.selected {
                    background-color: $datepicker-day-active-bg;
                    color: $datepicker-day-active-color;
                }

                &:not(.blank):not(.disabled):hover {
                    border-width: 0;
                }
            }
        }
    }
}