.section-venue {

    .map {
        padding: 22px 0 60px;

        @include media-breakpoint-up(md) {
            padding: 41px 0 101px;
        }

        img {
            max-width: 100%;
        }
    }
}