.card-package {
    margin: 10px 11px;
    max-width: 380px;
    
    @include media-breakpoint-up(sm) {
        max-width: 412px;
        border-width: 1px;
        padding: 15px;
    }
    
    @include media-breakpoint-up(lg) {
        max-width: none;
    }

    &.size-1 {
        width: 100%;
        
        @include media-breakpoint-up(lg) {
            width: 322px;
        }
    }

    &.size-2 {
        width: 100%;
        
        @include media-breakpoint-up(lg) {
            width: auto;
            flex-grow: 1;
        }
    }

    &.size-3 {
        width: 100%;
    }

    .head {
        height: 240px;
        background: center/cover;
        border-radius: $card-border-radius;
        margin: (-$card-border-width) (-$card-border-width) 0;
        
        @include media-breakpoint-up(sm) {
            margin: 0;
        }

        @include media-breakpoint-down(md) {
            background-image: none !important;
        }
    }
    
    .bg-mobile {
        background: center/cover;
        height: 100%;
        border-radius: $card-border-radius $card-border-radius 0 0;
        
        @include media-breakpoint-up(sm) {
            border-radius: $card-border-radius;
        }

        @include media-breakpoint-up(lg) {
            background-image: none !important;
        }
    }
    
    .date {
        border-radius: $card-border-radius $card-border-radius 0 0;
        background-color: $package-date-bg-color;
        color: $package-date-color;
        text-align: center;
        font-size: 14px;
        letter-spacing: .03em;
        padding: 13px 0;
    }

    .body {
        flex-grow: 1;
        min-height: 221px;
        padding: 0 10px;
        
        @include media-breakpoint-up(sm) {
            min-height: 214px;
            border-width: 0;
            padding: 0;
        }
    }
    
    .component-features {
        padding: 8px 0 8px 5px;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top-width: 0;
        padding: 0 15px 20px;
        border-radius: 0 0 $card-border-radius $card-border-radius;

        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }

    .price-wrapper {
        margin-top: 1px;
        letter-spacing: .02em;
        text-align: right;
        margin-right: 17px;
        
        @include media-breakpoint-up(sm) {
            margin-right: 20px;
        }
    }

    .price-text {
        font-weight: 300;
        font-size: 12px;
        color: rgba($default-color, .5);
    }
    
    .price {
        margin-top: 1px;
        font-size: 24px;
        font-weight: 700;
        color: rgba($default-color, .75);
        white-space: nowrap;
    }

    .btn {
        font-weight: 500;
        font-family: $font-secondary;
    }
}