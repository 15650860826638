.show-more-wrapper {
    position: relative;
    overflow: hidden;
    transition: height .6s linear;
    
    &.sm {
        height: 290px;
    }
    
    &.lg {
        height: 572px;

        &::after {
            content: " ";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -2px;
            box-shadow: 0 0 33px 44px $body-bg;
            
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {
            height: auto;
            overflow: auto;
        }
    }

    &[data-height-auto] {
        transition: height .6s linear, overflow 0s linear .6s;
        height: auto;
        overflow: hidden;

        &::after {
            bottom: -90px;
        }
    }
}