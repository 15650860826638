.component-video {
    display: flex;
    padding: 11px 0 10px;
    
    @include media-breakpoint-up(md) {
        display: block;
        padding: 37px 0 36px;
    }

    .image {
        position: relative;
        max-width: 320px;
        min-width: 1px;
        flex-grow: 1;

        &::after {
            content: " ";
            position: absolute;
            display: block;
            background: url("#{$path-img}play.png") center no-repeat;
            width: 50px;
            height: 50px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .img-wrapper{
        overflow: hidden;
        max-width: 100%;
        min-width: 1px;
        border-radius: 5px;
        display: flex;
        height: 100%;
        align-items: center;
        flex-direction: column;
    }

    .img {
        border-radius: 5px;
        display: block;
        transition-duration: .15s;
        opacity: 1;
        height: 100%;
        max-width: none;
    }

    .duration {
        background-color: $video-duration-bg-color;
        border-radius: 5px;
        color: $video-duration-color;
        font-size: 12px;
        display: block;
        bottom: 5px;
        left: 5px;
        position: absolute;
        padding: 3px 8px 3px 34px;
        
        @include media-breakpoint-up(md) {
            padding: 4px 8px 3px 34px;
            font-size: 14px;
        }

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            bottom: 0;
            background: $video-duration-icon-bg-color url("#{$path-img}duration.png") top 3px center no-repeat;
            display:block;
            margin-right: 5px;
            height: 100%;
            width: 25px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            @include media-breakpoint-up(md) {
                background-position: top 4px center;
            }
        }
    }

    .description {
        padding-left: 18px;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding-left: 0;
        }
    }

    .title {
        display: block;
        font-size: 14px;
        line-height: 1.2143;
        margin: 0 0 32px;
        word-break: break-word;
        font-weight: 700;
        color: $video-title-color;
        
        @include media-breakpoint-up(md) {
            font-size: $default-font-size;
            line-height: 1.1875;
            margin-top: 13px;
            margin-bottom: 17px;
        }

        span {
            border-bottom: 1px solid transparent;
            transition-duration: .15s;
        }
    }

    .time {
        word-spacing: 22px;
        color: $video-time-color;
        font-size: 12px;
        font-weight: 700;
        
        @include media-breakpoint-up(md) {
            word-spacing: 28px;
            font-size: 14px;
        }
    }

    &:hover {
        text-decoration: none;

        .img {
            opacity: .8;
        }

        .title span {
            border-color: $video-title-color;
        }
    }
}