.history-content-wrap {
    margin-bottom: 50px;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: 34px;
    }

    .image-wrap-right {
        min-width: 250px;
        text-align: center;
        padding: 0 15px;
    }
}