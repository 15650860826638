// ############ Fonts ############

@mixin font-face ($name, $file-name, $font-weight: 400, $font-style: normal) {
    @font-face {
        font-family: $name;
        src: url('#{$path-fonts}#{$file-name}.woff2') format('woff2'),
             url('#{$path-fonts}#{$file-name}.woff') format('woff'),;
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

$webbelliscup:  'webbelliscup';
$roboto:        'Roboto';
$arkipelago:    'arkipelago';

@include font-face($webbelliscup,   'webbelliscup-light-normal',      300, normal);
@include font-face($webbelliscup,   'webbelliscup-light-italic',      300, italic);
@include font-face($webbelliscup,   'webbelliscup-regular-normal',    400, normal);
@include font-face($webbelliscup,   'webbelliscup-regular-italic',    400, italic);
@include font-face($webbelliscup,   'webbelliscup-bold-normal',       700, normal);
@include font-face($webbelliscup,   'webbelliscup-bold-italic',       700, italic);
@include font-face($webbelliscup,   'webbelliscup-heavy-normal',      900, normal);
@include font-face($webbelliscup,   'webbelliscup-heavy-italic',      900, italic);

@include font-face($arkipelago,     'arkipelago');

$icomoon-font-path: "../fonts/icomoon/fonts";
@import "../../fonts/icomoon/style.scss";

$font-primary:      $webbelliscup,  sans-serif;
$font-secondary:    $roboto,        sans-serif;
$font-tertiary:     $arkipelago,    cursive;
$font-heading:      $font-secondary;