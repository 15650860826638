.dropdown {

    .dropdown-menu {
        width: 100%;

        display: block;
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s ease, visibility 0s ease .15s;
        box-shadow: $dropdown-box-shadow;
        padding: 20px 13px 10px 13px;
        margin-top: 0;

        &.with-scroll {
            padding-right: 0;
        }

        &.with-datepicker {
            width: auto;
            padding: 0;
        }

        &.with-search {
            padding: 0;
            border: $border-width solid $border-color;
            border-top-width: 0;
            border-radius: 0 0 $btn-border-radius-sm $btn-border-radius-sm;
            background-color: $dropdown-bg;

            @include media-breakpoint-up(lg) {
                box-shadow: none;
            }
            
            .dropdown-item {
                padding: 0;
                border-bottom: $border-width solid $border-color;
                
                &:last-of-type {
                    border-bottom-width: 0;
                    border-radius: 0 0 $btn-border-radius-sm $btn-border-radius-sm;

                    .btn {
                        border-radius: 0 0 $border-radius-sm $border-radius-sm;
                    }
                }

                &:hover {
                    background-color: $dropdown-bg;
                }
            }
        }
        
        &.show {
            transition: opacity .15s ease, visibility 0s ease 0s;
            visibility: visible;
            opacity: 1;
        }
    }

    &.show {

        .btn.btn-select {

            & + .icon {
                transform: scaleY(-1);
            }
        }
    }

    .show-more {
        margin-top: 10px;
        color: $dropdown-show-more-color;
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .02em;
        border-width: 0;
        background-color: transparent;
        padding: 0;
        outline-width: 0;
        border-bottom: 1px solid transparent;
        transition-duration: .15s;

        &:hover {
            border-color: $dropdown-show-more-color;
        }
    }
}