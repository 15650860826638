.section-gallery {

    .gallery {
        padding: 9px 0 0;
        
        @include media-breakpoint-up(lg) {
            padding: 35px 0 0;
        }
    }

    .img-row {
        display: flex;
    }
    
    .img-link {
        flex-grow: 1;
    }

    .img {
        width: 100%;
        height: 100%;
    }
}