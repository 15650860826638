.modal.modal-slider {

    .modal-dialog {
        margin: 154px auto;
        opacity: 0;
        transition: all .3s ease 0s;

        .modal-content {
            @include media-breakpoint-down(md) {
                background-color: transparent;
            }

            @include media-breakpoint-up(lg) {
                box-shadow: 0px 0px 30px rgba($default-color, .15);
            }
        }

        &.active {
            opacity: 1;
        }

        .close {
            margin: -32px 12px 0 auto;
            z-index: 1;
            
            @include media-breakpoint-up(lg) {
                margin: 2px 2px 0 auto;
            }

            .icon {
                color: $modal-slider-close-color-mobile;
                font-size: 12px;

                @include media-breakpoint-up(lg) {
                    color: rgba($default-color, .4);
                    font-size: 15px;
                }
            }
        }

        .modal-header {
            padding: 0;
            height: 0;
        }

        .modal-body {
            padding: 15px 32px;

            @include media-breakpoint-up(lg) {
                padding: 15px 50px;
            }
        }
    }
}

.backdrop-slider .modal-backdrop.show {
    opacity: .8;

    @include media-breakpoint-up(lg) {
        background-color: $body-bg;
        opacity: 1;
    }
}