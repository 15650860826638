.component-slider {
    position: relative;

    .lSSlideOuter {
        position: relative;

        .lSSlideWrapper {

            .lightSlider {

                & > * {
                    @include media-breakpoint-down(md) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        overflow: hidden;
                        justify-content: center;
                        height: 300px;
                    }

                    img {
                        @include media-breakpoint-down(md) {
                            max-width: none;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .lSPager.lSGallery {
            transition: transform .3s ease 0s;
            position: relative;
            z-index: 1;

            li {

                &.active,
                &:hover {
                    border-radius: 0;
                }
            }
        }

        &::before,
        &::after {
            display: none;
            content: " ";
            width: 80px;
            height: 47px;
            position: absolute;
            bottom: 0;
            z-index: 0;
            opacity: 0;
            transition: opacity .4s ease 0s, z-index 0s ease .4s;
            
            @include media-breakpoint-up(lg) {
                display: block;
            }
            
            @include media-breakpoint-up(xl) {
                height: 72px;
            }
        }

        &::before {
            background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FCFCFC 76.24%);
            left: 0;
        }

        &::after {
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FCFCFC 76.24%);
            right: 0
        }

    }

    .prev,
    .next {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 27px;
        text-align: center;
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 50%;
        margin-top: -16px;
        color: rgba($white, .7);
        display: flex;
        justify-content: center;
        align-items: center;
        
        @include media-breakpoint-up(lg) {
            color: rgba($default-color, .4);
            margin-top: 0;
            font-size: 20px;
            top: 136px;
        }
        
        @include media-breakpoint-up(xl) {
            top: 215px;
        }

        .icon-arrow-4 {
            
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .icon-arrow-5 {

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    .prev {
        transform: scaleX(-1);
        left: 0;
        
        @include media-breakpoint-up(lg) {
            left: 9px;
        }
    }
    
    .next {
        right: 0;
        
        @include media-breakpoint-up(lg) {
            right: 9px;
        }
    }
}

.thumbnail-gradient-left .lSSlideOuter::before,
.thumbnail-gradient-right .lSSlideOuter::after {
    transition: opacity .4s ease 0s, z-index 0s ease 0s;
}

.thumbnail-gradient-left .lSSlideOuter::before {
    opacity: 1;
    z-index: 2;
}

.thumbnail-gradient-right .lSSlideOuter::after {
    opacity: 1;
    z-index: 2;
}