.modal {

    .modal-dialog {
        margin: 111px auto;
    }

    .modal-header {
        padding-top: 19px;
        padding-bottom: 2px;

        .close {
            text-shadow: none;
            width: 40px;
            height: 40px;
            padding-top: 16px;
            opacity: 1;
            transition-duration: .15s;
            position: relative;
            font-size: 10px;
            color: rgba($default-color, .35);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .modal-title {
        font-size: 14px;
        letter-spacing: .02em;
        color: rgba($default-color, .35);
        text-transform: uppercase;
    }

    .modal-body {
        padding-top: 7px;
        padding-bottom: 30px;
    }

    .modal-content {
        min-width: 320px;
        box-shadow: $modal-content-box-shadow;
    }


    .modal-footer {
        box-shadow: $modal-footer-box-shadow;
    }
}