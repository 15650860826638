.card-match {
    flex-direction: row;
    text-align: center;
    transition-duration: .3s;
    overflow-y: hidden;
    height: 125px;
    margin: 20px 0;
    overflow-y: visible;
    
    @include media-breakpoint-up(md) {
        height: 180px;
        margin: 10px 0;
    }

    &.v-leave-active,
    &.v-enter-active {
        overflow-y: hidden;
    }
    
    &.v-enter, 
    &.v-leave-to {
        height: 0;
        opacity: 0;
        border-top-width: 0;
        border-bottom-width: 0;
        margin: 0;
    }
    
    &.static {
        overflow-y: visible;
        border-top-width: 1px;
        border-bottom-width: 1px;
        opacity: 1;
        height: auto;
        margin: 20px 0;

        @include media-breakpoint-up(md) {
            margin: 10px 0;
        }
    }

    .pool {
        margin: (-$card-border-width) 0 (-$card-border-width) (-$card-border-width);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-transform: uppercase;
        text-shadow: $match-pool-text-shadow;
        font-size: 12px;
        line-height: 1.25;
        color: $match-pool-color;
        background-image: $match-pool-bg-image;
        background-size: cover;
        padding: 0 8px;
        width: 25px;
        border-top-left-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;
        word-wrap: break-word;
        
        @include media-breakpoint-up(md) {
            padding: 0 13px;
            width: 38px;
            font-size: $default-font-size;
        }
        
        @each $pool, $color in $pools-colors {
            &.#{$pool} {
                background-color: $color;
            }
        }
    }
    
    .match {
        border-top-right-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-content: center;
        color: rgba($default-color, .75);
        position: relative;
    }

    .date {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: .03em;
        padding: 7px ($grid-gutter-width / 2) 12px;
        
        @include media-breakpoint-up(md) {
            padding-top: 11px;
            padding-bottom: 18px;
            font-size: $default-font-size;
        }
    }

    .sides {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .side {
        width: 123px;
        
        @include media-breakpoint-up(md) {
            width: 232px;
        }
    }

    .flag-img {
        margin-bottom: 9px;
        width: 31px;
        
        @include media-breakpoint-up(md) {
            margin: 0 2px 2px 0;
            width: 50px;
        }
    }

    .country {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .05em;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }

    .divider {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 16px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-top: 6px;
            padding-left: 27px;
            padding-right: 25px;
            font-size: 36px;
        }
    }

    .location {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: .03em;
        padding: 9px ($grid-gutter-width / 2) 7px;
        
        @include media-breakpoint-up(md) {
            padding-top: 18px;
            padding-bottom: 11px;
            font-size: $default-font-size;
        }
    }

    .categories {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 2px 0 3px;

        @include media-breakpoint-up(lg) {
            padding: 0;
            position: absolute;
            right: 15px;
            bottom: 14px;
            flex-direction: column;
        }
    }

    .category {
        position: relative;
        padding: 4px 0 4px 14px;
        font-size: 10px;
        letter-spacing: .03em;
        color: rgba($default-color, .5);
        margin: 0 6px;
        
        @include media-breakpoint-up(md) {
            margin: 0 16px;
            padding-left: 26px;
            font-size: 14px;
            color: rgba($default-color, .6);
        }

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        &::after {
            content: " ";
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 4px;
            left: 0;
            
            @include media-breakpoint-up(md) {
                width: 18px;
                height: 14px;
            }

            @include media-breakpoint-up(lg) {
                top: 5px;
            }
        }

        &.category-b {

            &::after {
                background: $match-category-b-color;
            }
        }

        &.category-c {

            &::after {
                background-color: $match-category-c-color;
            }
        }

        &.category-d {

            &::after {
                background-color: $match-category-d-color;
            }
        }
    }
}