.section-package-flights {

    .package-title {

        @include media-breakpoint-only(lg) {
            text-align: center;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 22px
        }
    }

    .flights {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px 0 13px;
        
        @include media-breakpoint-up(lg) {
            padding: 29px 0 34px;
            margin: 0 -15px;
        }
    }
}