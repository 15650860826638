.mCSB_inside {

    & > .mCSB_container {
        margin-right: 25px;
    }
}

.mCSB_scrollTools {
    opacity: 1;
    right: 4px;

    .mCSB_dragger {

        .mCSB_dragger_bar {
            border-radius: 0;
            background-color: $custom-scrollbar-color;
        }

        &:hover {

            .mCSB_dragger_bar {
                border-radius: 0;
                background-color: darken($custom-scrollbar-color, 10%);
            }
        }

        &.mCSB_dragger_onDrag {

            .mCSB_dragger_bar {
                background-color: darken($custom-scrollbar-color, 10%);
            }
        }
    }
    
    .mCSB_draggerRail {
        background-color: $custom-scrollbar-bg;
        width: 1px;
    }
}
