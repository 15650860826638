.section-main {

    margin-top: 63px;
    background: $section-main-bg;
    color: $section-main-color;
    
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
    }

    .column-1 {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 24%;
        }
    }

    .column-2 {
        width: 100%;
        padding: 0 21px;
        order: -1;
        
        @include media-breakpoint-up(lg) {
            width: 52%;
            padding: 0;
            order: 0;
        }
    }

    .column-3 {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 24%;
        }
    }

    .agent-logo {
        margin: 0 auto;
        
        @include media-breakpoint-up(lg) {
            margin: auto auto 38px 55px;
        }

        img {
            width: 160px;

            @include media-breakpoint-up(lg) {
                width: 140px;
            }
        }
    }
    
    .title-img {
        margin:  54px 0 46px;
        min-width: 1px;
        max-width: 100%;
        
        @include media-breakpoint-up(lg) {
            margin: 143px 0 144px;
        }
        
        img {
            max-height: 180px;
            max-width: 100%;
    
            @include media-breakpoint-up(lg) {
                max-height: none;
            }
        }
    }

    .copyright {
        margin: 56px auto 11px;

        @include media-breakpoint-up(lg) {
            margin: auto 16px 16px auto;
        }

        img {
            max-width: 100%;
            display: block;
            flex: 0 0 auto;
            margin-right: 10px;
            width: 20px;
            
            @include media-breakpoint-up(lg) {
                margin-right: 9px;
                width: 27px;
            }
        }

        span {
            font-weight: 700;
            font-size: 8px;
            display: block;
            
            @include media-breakpoint-up(lg) {
                font-size: 10px;
            }
        }
    }
}