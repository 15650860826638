.component-features {
    @extend .clearlist;

    .feature {
        position: relative;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: .03em;
        color: rgba($default-color, .75);
        padding: 8px 8px 8px 28px;

        .icon {
            position: absolute;
            left: 0;
            line-height: 12px;
            font-size: 11px;

            &.icon-badge {
                font-size: 18px;
            }
    
            &.icon-support {
                font-size: 18px;
            }
        }
    }
}