@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?azrkol') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?azrkol') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?azrkol##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close-3 {
  &:before {
    content: $icon-close-3; 
  }
}
.icon-arrow-4 {
  &:before {
    content: $icon-arrow-4; 
  }
}
.icon-arrow-5 {
  &:before {
    content: $icon-arrow-5; 
  }
}
.icon-close-2 {
  &:before {
    content: $icon-close-2; 
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane; 
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow; 
  }
}
.icon-arrow-2 {
  &:before {
    content: $icon-arrow-2; 
  }
}
.icon-arrow-3 {
  &:before {
    content: $icon-arrow-3; 
  }
}
.icon-badge {
  &:before {
    content: $icon-badge; 
  }
}
.icon-bed {
  &:before {
    content: $icon-bed; 
  }
}
.icon-breakfast {
  &:before {
    content: $icon-breakfast; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-conditioner {
  &:before {
    content: $icon-conditioner; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-ellipse {
  &:before {
    content: $icon-ellipse; 
  }
}
.icon-filters {
  &:before {
    content: $icon-filters; 
  }
}
.icon-humburger {
  &:before {
    content: $icon-humburger; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-location-2 {
  &:before {
    content: $icon-location-2; 
  }
}
.icon-loupe {
  &:before {
    content: $icon-loupe; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-social-facebook {
  &:before {
    content: $icon-social-facebook; 
  }
}
.icon-social-instagram {
  &:before {
    content: $icon-social-instagram; 
  }
}
.icon-social-twitter {
  &:before {
    content: $icon-social-twitter; 
  }
}
.icon-social-youtube {
  &:before {
    content: $icon-social-youtube; 
  }
}
.icon-support {
  &:before {
    content: $icon-support; 
  }
}
.icon-transfer-desktop {
  &:before {
    content: $icon-transfer-desktop; 
  }
}
.icon-transfer-mobile {
  &:before {
    content: $icon-transfer-mobile; 
  }
}
.icon-wi-fi {
  &:before {
    content: $icon-wi-fi; 
  }
}

