.component-collapse {
    padding: 5px 0;

    .title {
        position: relative;
        cursor: pointer;
        background-color: $collapse-title-bg-color, $collapse-title-bg-color;
        background-image: $collapse-title-bg-image;
        background-size: cover, cover;
        line-height: 1.5625;
        letter-spacing: .02em;
        color: $collapse-title-color;
        font-weight: 700;
        padding: 4px 55px 6px 10px;
        box-shadow: $collapse-title-box-shadow;
        min-height: 50px;
        display: flex;
        align-items: center;
        font-size: $default-font-size;
        
        @include media-breakpoint-up(md) {
            padding: 4px 63px 6px 11px;
            min-height: 53px;
            font-size: 18px;
        }

        &::before{
            content:"";
            min-height:inherit;
            font-size:0;
        }

        .icon {
            position: absolute;
            padding-bottom: 1px;
            right: 25px;
            font-size: 10px;
            color: rgba($collapse-title-color, .7);
            transition-duration: .3s;
            transform: scaleY(-1);
            font-weight: 700;
            margin-top: -6px;
            top: 50%;

            @include media-breakpoint-up(md) {
                right: 32px;
            }
        }
        
        &.collapsed {
            font-weight: 400;
            
            .icon {
                transform: scaleY(1);
                padding-top: 2px;
            }
        }
    }

    .text-wrapper {
        position: relative;

        &::before {
            content: " ";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $collapse-border-color;
            position: absolute;
            left: 0;
            top: 0;
        }

        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $collapse-border-color;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .text {
        border: 1px solid $collapse-border-color;
        background-color: $collapse-text-background-color;
        color: rgba($default-color, .9);
        padding: 10px 0 14px;
        
        @include media-breakpoint-up(md) {
            padding: 22px 0;
        }

        ul {
            @extend .clearlist;
        }

        li {
            @extend .icon-ellipse;

            position: relative;
            font-size: 14px;
            line-height: 1.5714;
            letter-spacing: .03em;
            padding: 9px 41px 10px;
            
            @include media-breakpoint-up(md) {
                font-size: $default-font-size;
                line-height: 1.5625;
                padding-top: 8px;
                padding-bottom: 8px;
            }

            &::before {
                font-family: icomoon;
                font-size: 9px;
                position: absolute;
                left: 19px;
                top: 13px;
                color: rgba($collapse-item-icon-color, .75);

                @include media-breakpoint-up(md) {
                    top: 15px;
                }
            }
        }
    }
}