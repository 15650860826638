.card-list {
    margin-bottom: 88px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 163px;
    }
}

.card-history {
    margin-bottom: 20px;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
    }

    .card-header {
        margin: (-$card-border-width) (-$card-border-width) 0px;
        color: $history-header-body-color;
        border-bottom: none;

        .card-title {
            font-size: $default-font-size;
            margin-bottom: 7px;
            letter-spacing: .0625em;
            font-weight: 700;
        }

        .card-text {
            font-size: 14px;
            letter-spacing: .0714em;
        }
    }

    .card-body {
        margin: 0 (-$card-border-width);
        color: $history-header-body-color;
        letter-spacing: .03em;
        padding: 19px 20px 15px;
        overflow: hidden;

        .content-wrap {
            margin-right: 35px;
        }

        .card-title {
            font-size: $default-font-size;
            margin-bottom: 8px;
            font-weight: 700;
        }
        
        .card-text {
            line-height: 1;
            font-weight: 300;
            padding-bottom: 6px;
        }

        .flag-img {
            width: 78px;
            height: 95px;
            margin-right: 3px;
        }
    }

    .card-img-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        min-height: 100%;
        border-radius: 0;
        object-fit: cover;
        z-index: -1;
    }

    .list-group {
        color: rgba($default-color, .75);
        
        @include media-breakpoint-up(sm) {
            padding: 0 30px;
        }
        
        .list-group-item {
            padding: 25px 0;
            
            .title {
                letter-spacing: .03em;
                margin-right: 17px;
                font-weight: 700;
            }
            
            .text {
                letter-spacing: .03em;
                font-weight: 700;
                font-size: 32px;
                padding-right: 6px;

                @include media-breakpoint-up(sm) {
                    padding-right: 26px;
                }
            }
        }
    }
}