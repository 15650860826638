.modal-filters {

    .heading {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        letter-spacing: .02em;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba($default-color, .65);
        transition-duration: .15s;
        padding: 4px 0;

        .icon {
            transition-duration: .3s;
            color: rgba($default-color, .45);
            position: absolute;
            right: 0;
            
            &.icon-minus {
                top: 10px;
                font-size: 2px;
                transform: scaleY(1);
            }
            
            &.icon-plus {
                top: 7px;
                font-size: 9px;
                transform: scaleY(0);
            }
        }
        

        &:hover {
            opacity: .75;
        }

        &.collapsed {

            .icon-minus {
                transform: scaleY(0);
            }
            
            .icon-plus {
                transform: scaleY(1);
            }
        }
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
    }

    .reset,
    .apply {
        width: 147px;
        margin: 0 3px;
        font-size: 11px;
        letter-spacing: 0;
        padding: 3px 10px 4px;
        font-weight: 500;
    }
}