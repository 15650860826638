.btn {
    text-transform: uppercase;
    letter-spacing: .02em;

    &.btn-search {
        border: 1px solid $border-color;
        background: $btn-search-bg;
        padding-left: 9px;
        padding-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: rgba($default-color, .45);

        &:hover {
            background-color: $btn-search-bg-color-hover;
        }
    }

    &.btn-select {
        width: 100%;
        font-family: $font-primary;
        font-weight: 400;
        font-size: 18px;
        border: 1px solid $btn-select-border-color;
        background-color: $btn-select-bg-color;
        color: rgba($default-color, .7);
        border-radius: 0;
        text-align: left;
        padding: 8px 9px;
        transition-duration: .15s;
        letter-spacing: 0;
        
        &:hover {
            border-color: darken($btn-select-border-color, 10%);
        }
        
        & + .icon {
            display: flex;
            width: 50px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            transition-duration: .15s;
            transform: scaleY(1);
            z-index: 2;
            color: rgba($default-color, .35);
            font-size: 9px;
            justify-content: center;
            align-items: center;
        }
        
        &.active {
            font-weight: 700;
            text-transform: none;
        }
    }
    
    &.btn-reset {
        letter-spacing: 0;
        width: 100%;
        font-family: $font-primary;
        font-weight: 400;
        font-size: 18px;
        border: 1px solid $btn-reset-border-color;
        background-color: $btn-reset-bg-color;
        text-transform: none;
        color: $btn-reset-color;
        border-radius: 0;
        padding: 8px 9px;

        &:hover {
            border-color: darken($btn-reset-border-color, 10%);
        }
    }

    &.btn-filters {
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 14px;
        border: 1px solid $btn-filters-border-color;
        color: rgba($default-color, .35);
        padding: 7px 20px 7px 20px;
        transition-duration: .15s;
        text-transform: none;
        position: relative;
        background-color: $btn-filters-bg-color;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:hover {
            border-color: darken($btn-filters-border-color, 10%);
        }

        .icon {
            font-size: 17px;
            padding-right: 11px;
        }
    }

    &.btn-filter {
        font-family: $font-secondary;
        font-weight: 400;
        border: 1px solid $btn-filter-border-color;
        background-color: $btn-filter-bg-color;
        color: rgba($default-color, .35);
        transition-duration: .15s;
        text-transform: none;
        cursor: pointer;
        letter-spacing: 0;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            min-height: 40px;
            height: 1px;
            font-size: $default-font-size;
            line-height: 1.1875;
            color: $btn-filter-color-desktop;
            border-color: $btn-filter-border-color-desktop;
            font-family: $font-primary;
            text-shadow: -1px 0 0 transparent;
            transition: all .15s ease;
        }
        
        &:hover {
            
            @include media-breakpoint-up(lg) {
                text-shadow: -1px 0 0 $btn-filter-color-desktop;
                background-color: $btn-filter-bg-hover-desktop;
                border-color: $btn-filter-active-border-color-desktop;
            }
        }

        &:not(.btn-block) {
            margin: 4px;
        }

        &.active {
            box-shadow: 0px 0px 30px $btn-filter-active-box-shadow-color;
            color: rgba($default-color, .8);
            border-color: $btn-filter-active-border-color;

            @include media-breakpoint-up(lg) {
                box-shadow: none;
                color: $btn-filter-color-desktop;
                border-color: $btn-filter-active-border-color-desktop;
                text-shadow: -1px 0 0 $btn-filter-color-desktop;
                background-color: $btn-filter-bg-hover-desktop;
            }
        }

        @each $pool, $color in $pools-colors {
            &.#{$pool} {

                &.active,
                &:hover {
                    box-shadow: 0px 0px 30px rgba($color, .2);
                    color: $color;
                    border-color: darken($color, 10%);
    
                    @include media-breakpoint-up(lg) {
                        color: $btn-filter-bg-color;
                        text-shadow: -1px 0 0 $btn-filter-bg-color;
                        background-color: $color;
                    }
                }
            }
        }
    }
    
    &.btn-gradient {
        background-image: $btn-gradient-bg-image;
        text-transform: none;
        font-weight: 700;
        font-family: $font-primary;
    }
}

.btn-search-variant {
    display: block;
    width: 100%;
    padding: 12px 11px 9px;
    color: rgba($default-color, .35);
    text-align: left;
    text-transform: none;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    
    @include media-breakpoint-up(lg) {
        padding-bottom: 11px;
        font-size: 18px;
        color: rgba($default-color, .65);
        background-color: rgba($default-color, .05);
    }
    
    &:hover {
        color: rgba($default-color, .75);

        @include media-breakpoint-up(lg) {
            background-color: rgba($default-color, .1);
        }
    }
}
    
.btn-padding-sm {
    padding-left: $btn-padding-x - 13px;
    padding-right: $btn-padding-x - 13px;
}

.btn-lg {
    padding-top: $btn-padding-y-lg;
    padding-bottom: $btn-padding-y-lg + 1;
}

.btn-xl {
    padding: 5px 18px 6px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 14px;
    
    @include media-breakpoint-up(lg) {
        padding: 18px 29px 19px;
        font-size: 20px;
    }
}

.dropdown-with-arrow,
.btn-reset-label {
    position: relative;
    width: 20%;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        width: 48px;
        height: 38px;
        right: 1px;
        top: 1px;
        background-color: $btn-select-bg-color;
        box-shadow: -10px 0 5px -5px $btn-select-bg-color;
        z-index: 1;
    }
}

.btn-select-label {
    position: relative;
    width: 100%;
}