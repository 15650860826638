.section-package-price {
    padding: 31px 0 6px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 5px;
    }

    .container {
        display: flex;
    }

    .text {
        padding-right: 12px;
        font-size: 24px;
        letter-spacing: .03em;
        display: flex;
        align-items: flex-end;
        color: rgba($default-color, .75);
        
        @include media-breakpoint-up(lg) {
            padding-right: 10px;
            font-size: 36px;
        }
    }
    
    .info {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }
    
    .from {
        padding: 0 0 5px 1px;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: .05em;
        color: rgba($default-color, .5);
        
        @include media-breakpoint-up(lg) {
            padding: 0 0 7px 2px;
            font-size: 14px;
        }
    }
    
    .price {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: .05em;
        color: rgba($default-color, .8);
        white-space: nowrap;
        
        @include media-breakpoint-up(lg) {
            font-size: 36px;
        }
    }
}