.card-pool {
    width: 238px;
    text-align: center;
    padding: 26px 21px 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin: ($grid-gutter-width / 2) 0;

    .title {
        display: inline-block;
        align-self: center;
        margin-bottom: -11px;
        position: relative;
        font-size: 24px;
        width: 178px;
        letter-spacing: .04em;
        background: $card-bg;
    }

    .countries {
        flex-grow: 1;
        border: 1px solid $card-border-color;
        border-radius: 5px;
        padding: 12px 10px 9px;
    }

    .country {
        font-size: 20px;
        margin-top: 11px;
        letter-spacing: .02em;
    }

    @each $pool, $color in $pools-colors {
        &.#{$pool} {

            .title {
                color: $color;
            }
            
            .countries {
                border-color: $color;
            }
        }
    }

    @each $pool, $color in $pools-countries-colors {
        &.#{$pool} {
            
            .country {
                color: $color;
            }
        }
    }
}