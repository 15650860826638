.section-matches-filters {
    padding: 0 0 47px;

    @include media-breakpoint-up(lg) {
        padding: 0 0 53px;
    }
    
    .input-group-search {
        padding-top: 20px;
        margin-bottom: 10px;
        
        @include media-breakpoint-up(lg) {
            padding-top: 33px;
        }
    }
    
    .input-group-filters {
        padding: 39px 0 31px;
        display: none;
        
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
    
    .input-group-filters-m {
        padding: 10px 0 1px;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .btn-gradient {
        margin-top: 37px;
    }

    .matches {
        overflow: hidden;
        
        @include media-breakpoint-up(md) {
            padding-top: 5px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
    }
}