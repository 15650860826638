// ############ Default ############

// Html 
$default-font-family:   $font-primary;
$default-font-size:     16px;
$default-line-height:   1;
$default-font-weight:   400;
$default-font-style:    normal;
$default-color:         $dark;

// Page content wrap
$page-content-wrap-color: $gray-600;

// ############ Modals ############

// Slider
$modal-slider-close-color-mobile: $white;

// ############ Sections ############

// Main
$section-main-bg:       $secondary url("#{$path-img}main-bg.jpg") center/cover;
$section-main-color:    $white;

// Footer
$footer-bg-image:       url("#{$path-img}footer-bg.png"),   linear-gradient(250.26deg, $red-2 2.06%, $pink 59.34%);
$footer-bg-image-m:     url("#{$path-img}footer-bg-m.png"), linear-gradient(263.97deg, $red-2 2.06%, $pink 59.34%);
$footer-bg-color:       $pink;
$footer-color:          $white;

// ############ Package Sections ############

// Main
$section-package-main-bg-color:         $secondary;
$section-package-main-color:            $white;
$section-package-main-overlay-bg-color: $dark;

// Includes
$section-package-includes-link-color:   $blue-4;

// ############ Components ############

// Info
$info-icon-color:   $red-4;

// Buttons

// Search buttons
$btn-search-border-color:   $gray-400;
$btn-search-border-color:   $gray-400;
$btn-search-bg:             rgba($dark, .05);
$btn-search-bg-color-hover: rgba($dark, .1);

// Select buttons
$btn-select-border-color:   $gray-300;
$btn-select-bg-color:       $white;

// Reset buttons
$btn-reset-border-color:    $gray-300;
$btn-reset-bg-color:        $white;
$btn-reset-color:           rgba($red-2, .8);

// Filters button
$btn-filters-border-color:  $gray-400;
$btn-filters-bg-color:      $white;

// Filter button
$btn-filter-border-color:                   $gray-375;
$btn-filter-bg-color:                       $white;
$btn-filter-active-border-color:            darken($btn-filter-border-color, 40%);
$btn-filter-active-box-shadow-color:        rgba($btn-filter-active-border-color, .2);
$btn-filter-border-color-desktop:           $gray-300;
$btn-filter-padding-y-desktop:              8px;
$btn-filter-color-desktop:                  rgba($black, .6);
$btn-filter-bg-hover-desktop:               rgba($dark, .05);
$btn-filter-active-border-color-desktop:    rgba($dark, .1);

// Gradient button
$btn-gradient-bg-image: linear-gradient(180deg, $red-2 0%, $pink 100%);

// Dropdown
$dropdown-show-more-color:  $blue-3;
$dropdown-scroll-color:     $blue-3;

// Custom scrollbar
$custom-scrollbar-bg:       rgba($dark, .1);
$custom-scrollbar-color:    $blue-3;

// Datepicker
$datepicker-day-active-color:    rgba($white, .9);
$datepicker-day-active-bg:       $blue-3;

// Navbar
$navbar-toggler-icon-color: $light;

// Nav tabs
$nav-tabs-bg:                   $body-bg;
$nav-tabs-border-bg:            linear-gradient(90deg, rgba($black, .19), rgba($black, .06));
$nav-tabs-border-active-bg:     $red-2;
$nav-tabs-border-active-shadow: 0px -4px 15px rgba($red-2, .55);
$nav-tabs-border-active-white:  $body-bg;
$nav-tabs-copy-box-shadow:      0px 4px 10px rgba($black, .1);

// Modal
$modal-content-box-shadow:  0px -3px 10px rgba($dark, .1);
$modal-footer-box-shadow:   0px -2.70435px 9.01449px rgba($dark, .1);

// Collapse
$collapse-title-bg-color:           $purple-2;
$collapse-title-bg-image:           url("#{$path-img}collapse-title-bg-pattern.png"), linear-gradient(90deg, $purple-2 13.87%, rgba($purple-2-light, .81) 118.81%);
$collapse-title-color:              $white;
$collapse-title-box-shadow:         0px 0px 30.7315px rgba($dark, .05);
$collapse-border-color:             $gray-450;
$collapse-text-background-color:    $light;
$collapse-item-icon-color:          $red-2;

// Match
$match-pool-color:              rgba($white, .9);
$match-pool-text-shadow:        0px 4px 15px rgba($black, .2);
$match-pool-bg-image:           url("#{$path-img}match-bg-pattern.png");
$match-category-b-color:        $blue-5;
$match-category-c-color:        $yellow;
$match-category-d-color:        $green;

// Pool
$color-pool-a-country:  $blue-dark;
$color-pool-b-country:  $red-dark;
$color-pool-c-country:  $blue-2-dark;
$color-pool-d-country:  $purple-dark;

$pools-countries-colors: () !default;
$pools-countries-colors: map-merge(
    (
        "pool-a":         $color-pool-a-country,
        "pool-b":         $color-pool-b-country,
        "pool-c":         $color-pool-c-country,
        "pool-d":         $color-pool-d-country,
    ),
    $pools-countries-colors
);

// Video
$video-duration-icon-bg-color:   #9b3cbc;
$video-duration-bg-color:        #490d67;
$video-duration-color:           $white;
$video-title-color:              rgba($black, .8);
$video-time-color:               rgba($black, .5);

// Package
$package-date-bg-color:     rgba($dark, .3);
$package-date-color:        rgba($white, .8);

// History
$history-header-body-color: $light;

// Flight
$flight-head-color:             $white;
$flight-divider-color-desktop:  $gray-290;
$flight-divider-color-mobile:   $gray-270;

// Carousel
$carousel-nav-bg-color:     $light;

// Get package
$get-package-box-shadow-color:      $black;
$get-package-border-color-desktop:  $light;
$get-package-border-color-mobile:   $gray-345;
$get-package-bg-color:              $white;

// Hotel
$hotel-location-icon-color: $black;