footer {
    background-image: $footer-bg-image-m;
    background-repeat: no-repeat;
    background-position: left bottom, center;
    background-size: 46.1%, auto;
    color: $footer-color;
    font-family: $font-secondary;
    font-weight: 300;
    text-align: center;
    padding: 76px 0 18px;
    
    @media (min-width: 546px) {
        background-size: 251px, auto;
    }
    
    @include media-breakpoint-up(lg){
        background-size: auto;
        background-image: $footer-bg-image;
        background-position: right 142px bottom, center;
        padding: 35px 0 24px;
        text-shadow: 0 1px 0 $footer-bg-color, 0 -1px 0 $footer-bg-color, 1px 0 0 $footer-bg-color, -1px 0 0 $footer-bg-color;
    }
    
    .content {

        @include media-breakpoint-up(lg){
            width: 50%;
        }
    }

    .logo {
        display: inline-block;
        
        @include media-breakpoint-up(lg){
            margin-right: 75px;
        }
        
        img {
            width: 105px;

            @include media-breakpoint-up(lg){
                width: 71px;
            }
        }
    }

    .text-wrapper {
        padding-top: 78px;
        
        @include media-breakpoint-up(lg){
            padding-top: 35px;
        }

        .text {
            text-align: left;
            margin-top: 7px;
            font-size: 10px;
            line-height: 1.5;
            letter-spacing: .02em;
            padding: 0 12% 0 51%;
            min-height: 190px;
            
            @include media-breakpoint-up(lg){
                min-height: 0;
                text-align: right;
                font-size: 14px;
                line-height: 1.4286;
                width: 50%;
                padding: 0;
            }
        }
        
        .nav {
            width: 50%;
            padding-left: 58px;

            .nav-item {
                text-align: left;
            }

            .nav-link {
                color: $footer-color;
            }
        }
    }

    .title {
        padding-top: 44px;
        text-transform: uppercase;
        letter-spacing: .31em;
        font-size: 14px;
        font-weight: 700;
        
        @include media-breakpoint-up(lg){
            padding-top: 81px;
            font-weight: 300;
        }
    }

    .component-socials {
        margin-top: 14px;
    }

    .copyright {
        padding: 93px 0 0 82px;
        
        @include media-breakpoint-up(lg){
            padding: 155px 0 0;
        }

        p {
            margin: 2px 0;
            letter-spacing: .02em;
            font-size: 8px;
            line-height: 1;
            color: $footer-color;
            
            @include media-breakpoint-up(lg){
                font-size: 10px;
            }
        }
    }
}