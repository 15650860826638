$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-close-3: "\e91e";
$icon-arrow-4: "\e91b";
$icon-arrow-5: "\e91c";
$icon-close-2: "\e91d";
$icon-airplane: "\e900";
$icon-arrow: "\e901";
$icon-arrow-2: "\e902";
$icon-arrow-3: "\e903";
$icon-badge: "\e904";
$icon-bed: "\e905";
$icon-breakfast: "\e906";
$icon-calendar: "\e907";
$icon-close: "\e908";
$icon-conditioner: "\e909";
$icon-download: "\e90a";
$icon-ellipse: "\e90b";
$icon-filters: "\e90c";
$icon-humburger: "\e90d";
$icon-location: "\e90e";
$icon-location-2: "\e90f";
$icon-loupe: "\e910";
$icon-minus: "\e911";
$icon-plus: "\e912";
$icon-social-facebook: "\e913";
$icon-social-instagram: "\e914";
$icon-social-twitter: "\e915";
$icon-social-youtube: "\e916";
$icon-support: "\e917";
$icon-transfer-desktop: "\e918";
$icon-transfer-mobile: "\e919";
$icon-wi-fi: "\e91a";

