.nav-tabs-wrapper {
    position: relative;
    display: inline-block;
    max-width: 100%;
    transition-duration: .3s;

    &.hidden {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .nav-tabs {
        background: $nav-tabs-bg;
        padding-top: 10px;
        padding-bottom: 25px;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        
        @include media-breakpoint-up(md) {
            display: inline-flex;
        }
        
        .nav-item {
            padding: 0 19px;
            display: inline-block;
            
            .nav-link {
                position: relative;
                font-size: 14px;
                letter-spacing: .02em;
                color: $default-color;
                text-shadow: -1px 0 0 transparent;
                transition-duration: .15s;

                &::before {
                    content: " ";
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: -27px;
                    z-index: 1;
                    margin-left: -14px;
                    height: 2px;
                    width: 28px;
                    background-color: transparent;
                    transition-duration: .15s;
                }

                &::after {
                    content: " ";
                    display: block;
                    position: absolute;
                    height: 2px;
                    bottom: -27px;
                    width: 100%;
                    border-left: 8px solid $nav-tabs-bg;
                    border-right: 8px solid $nav-tabs-bg;
                    background-color: transparent;
                    box-shadow: 0px -4px 15px transparent;
                    transition-duration: .15s;
                }
                
                &.active {
                    cursor: default;
                    text-shadow: -1px 0 0 $default-color;

                    &::before {
                        background-color: $nav-tabs-border-active-bg;
                    }

                    &::after {
                        background-color: $nav-tabs-border-active-bg;
                        box-shadow: $nav-tabs-border-active-shadow;
                    }
                }
            }
        }
        
        &::after {
            content: " ";
            display: block;
            position: absolute;
            bottom: 7px;
            left: 0;
            height: 1px;
            width: 100%;
            background-image: $nav-tabs-border-bg;
            
            @include media-breakpoint-up(md) {
                bottom: 9px;
            }
        }
            
    }
    
    &.copy {
        display: block;

        .nav-tabs {
            padding-bottom: 11px;
            box-shadow: $nav-tabs-copy-box-shadow;
    
            @include media-breakpoint-up(md) {
                display: flex;
            }
    
            .nav-item:first-of-type {
                
                @include media-breakpoint-up(lg) {
                    margin-left: 220px;
                }
            }
    
            .nav-link {
    
                &::before {
                    bottom: -21px;
                }
    
                &::after {
                    bottom: -21px;
                }
            }
    
            &::after {
                display: none;
            }
        }
    }
}